<template>
  <div class="contact-page">
    <div class="contact-head-wrapper">
      <div class="contact-page__header">
        <Header :appMenuStatusUpdate="appMenuStatusUpdate" />
      </div>
      <div class="contact-page__contact-container">
        <Contact />
      </div>
      <div class="subscribe-bar">
        <SubscribeBar :showSusbscribeBar="showSusbscribeBar" :closeSubscribeBar="closeSubscribeBar" />
      </div>
    </div>
    <div class="contact-page__footer">
      <Footer />
    </div>
  </div>
</template>
<script>
import { Header, Contact, Footer, SubscribeBar } from '@/components'
export default {
  name: 'ContactPage',
  components: {
    Footer,
    Contact,
    Header,
    SubscribeBar
  },
  data() {
    return {
      showSusbscribeBar: true
    }
  },
  methods: {
    closeSubscribeBar() {
      this.showSusbscribeBar = false
    },
    appMenuStatusUpdate(updatedStatus) {
      this.showSusbscribeBar = updatedStatus
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/scss/media-query-mixins.scss';
@import '@/assets/scss/colors';
.contact-page {
  .contact-head-wrapper {
    min-height: calc(70vh);
  }
  &__header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $primary-white;
  }
  &__contact-container {
    margin-top: 100px;
    @include iphone-portrait {
      margin-top: 13vh;
    }
    @include ipad-portrait {
      margin-top: 70px !important;
    }
    @include ipad-10-landscape {
      margin-top: 170px;
    }
    @include larger-screens {
      margin-top: 150px;
      margin-bottom: 50px;
    }
  }
}
</style>
